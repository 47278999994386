import React, { Fragment, useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import styles from "../scss/components/_Footer.module.scss"
import ModalConditions from "./ModalConditions.js"
import ModalPrivacy from "./ModalPrivacy.js"
import instagram from "../images/icons/instagram.svg"
import facebook from "../images/icons/facebook.svg"
import twitter from "../images/icons/x.svg"
import youtube from "../images/icons/youtube.svg"

const Footer = ({ summer }) => {
  const [modalCondition, setmodalCondition] = useState(null)
  const [modalPrivacy, setmodalPrivacy] = useState(null)

  return (
    <Fragment>
      <Container fluid className={styles.Footer}>
        <Row fluid>
          <Col className={styles.FooterContainerUp}>
            <Row className="text-center justify-content-center">
              <Col xs={"auto"} className="p-0 m-0">
                <a
                  href="mailto:budweiser.ar@gmail.com"
                  target="__blank"
                  className={styles.FooterLink}
                  onClick={() => {
                    return window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Footer",
                      event_action: "Click",
                      event_label: "",
                      interaction: true,
                      component_name: "",
                      element_text: "",
                    })
                  }}
                >
                  <p>CONTACTO</p>
                </a>
                <Button
                  href="/Terminos-Condiciones.pdf"
                  target="blank"
                  onClick={() => {
                    return window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Footer",
                      event_action: "Click",
                      event_label: "",
                      interaction: true,
                      component_name: "",
                      element_text: "",
                    })
                  }}
                  className={styles.FooterButton}
                >
                  <p>TÉRMINOS Y CONDICIONES</p>
                </Button>
                {summer ? (
                  <Button
                    href="/Bases y Condiciones  Promoción la Música en tus manos.pdf"
                    className={styles.FooterButton}
                    onClick={() => {
                      window.dataLayer.push({
                        event: "GAEvent",
                        event_category: "Footer",
                        event_action: "Click",
                        event_label: "",
                        interaction: true,
                        component_name: "",
                        element_text: "",
                      })
                    }}
                  >
                    <p>BASES Y CONDICIONES</p>
                  </Button>
                ) : null}
                <Button
                  href="/PoliticadePrivacidad.pdf"
                  target="blank"
                  className={styles.FooterButton}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Footer",
                      event_action: "Click",
                      event_label: "",
                      interaction: true,
                      component_name: "",
                      element_text: "",
                    })
                  }}
                >
                  <p>POLÍTICA DE PRIVACIDAD</p>
                </Button>
                <a
                  href="https://www.cerveceriaymalteriaquilmes.com/sustentabilidad-consumo-responsable-anterior/"
                  target="__blank"
                  className={styles.FooterLink}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Footer",
                      event_action: "Click",
                      event_label: "",
                      interaction: true,
                      component_name: "",
                      element_text: "",
                    })
                  }}
                >
                  <p>CONSUMO RESPONSABIE</p>
                </a>
                <a
                  href="https://www.cerveceriaymalteriaquilmes.com/"
                  target="__blank"
                  className={styles.FooterLink}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Footer",
                      event_action: "Click",
                      event_label: "",
                      interaction: true,
                      component_name: "",
                      element_text: "",
                    })
                  }}
                >
                  <p>INFORMACIÓN OFICIAL DE LA COMPAÑÍA</p>
                </a>
              </Col>
              <Col xs={"auto"} className="d-flex align-items-center p-0 m-0">
                <span className={styles.FooterLinkSeparator}>|</span>
              </Col>
              <Col xs={"auto"} className="d-flex align-items-center p-0 m-0">
                <a
                  href="https://www.instagram.com/budweiser_ar/"
                  target="__blank"
                  className={styles.FooterLinkIcon}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Social",
                      event_action: "Click",
                      event_label: "https://www.instagram.com/budweiser_ar/",
                      interaction: true,
                      component_name: "boton_instagram",
                      element_text: "Instagram",
                    })
                  }}
                >
                  <img className={styles.FooterIcon} src={instagram} />
                </a>
                <a
                  href="https://www.facebook.com/budweiser_ar/"
                  target="__blank"
                  className={styles.FooterLinkIcon}
                  onClick={() => {
                    window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Social",
                      event_action: "Click",
                      event_label:
                        "https://www.facebook.com/budweiser_ar/",
                      interaction: true,
                      component_name: "boton_facebook",
                      element_text: "Facebook",
                    })
                  }}
                >
                  <img className={styles.FooterIcon} src={facebook} />
                </a>
                <a
                  href="https://twitter.com/budweiser_ar"
                  target="__blank"
                  className={styles.FooterLinkIcon}
                  onClick={e => {
                    return window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Social",
                      event_action: "Click",
                      event_label: "https://twitter.com/budweiser_ar",
                      interaction: true,
                      component_name: "boton_twitter",
                      element_text: "Twitter",
                    })
                  }}
                >
                  <img className={styles.FooterIcon} src={twitter} />
                </a>
                <a
                  className={styles.FooterLinkIcon}
                  onClick={e => {
                    return window.dataLayer.push({
                      event: "GAEvent",
                      event_category: "Social",
                      event_action: "Click",
                      event_label: "https://www.youtube.com/@budweiserar",
                      interaction: true,
                      component_name: "boton_youtube",
                      element_text: "Youtube",
                    })
                  }}
                  href="https://www.youtube.com/@budweiserar"
                  target="__blank"
                >
                  <img className={styles.FooterIcon} src={youtube} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className={styles.FooterContainerDown}>
            <Row className={styles.FooterRowDown}>
              <Col xs={"auto"} className={styles.FooterTextDown}>
                <p>
                  BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.
                  NO COMPARTA EL CONTENIDO CON MENORES.
                </p>
              </Col>

              <Col xs={"auto"} className={styles.FooterLaws}>
                <p>
                  ® 2021 Budweiser - Buenos Aires - Argentina. Todos los
                  derechos reservados.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <ModalConditions show={modalCondition} />
      <ModalPrivacy show={modalPrivacy} />
    </Fragment>
  )
}

export default Footer
